<template>
  <div>
    <v-breadcrumbs :items="items"></v-breadcrumbs>
    <v-divider></v-divider>
    <add-client
      @create="createClient($event)"
      class="mt-5"
      :client="client"
      :agencies="agencies"
      @update="update_Client($event)"
    />
  </div>
</template>

<script>
import AddClient from "../../../components/Users/AddClient.vue";

export default {
  data: () => ({
    items: [
      {
        text: "Clients",
        disabled: false,
        href: "#/users-management/clients",
      },
      {
        text: "Add New Client/edit",
        disabled: true,
        href: "#",
      },
    ],
    select: null,
  }),

  components: {
    AddClient,
  },

  async mounted() {
    if (this.$route.name === "update-client") {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("readClient", this.$route.params.id);
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    }

    //Agencies
    try {
      this.$store.commit("makingApiRequest", true);
      await this.$store.dispatch("readAllAgencies", 100);
      this.$store.commit("makingApiRequest", false);
    } catch (error) {
      this.error = error;
    }
  },
  computed: {
    clients() {
      return this.$store.state.clients;
    },
    client() {
      return this.$store.state.client;
    },
    agencies() {
      return this.$store.state.all_agencies;
    },
  },

  methods: {
    async createClient(body) {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("addNewClient", body);
        this.$store.commit("makingApiRequest", false);
        this.$notify({
          title: "Client created successfully!",
          group: "success",
        });

        await this.$router.push({
          name: "all-clients",
          params: { id: this.agency_id },
        });
        await this.$router.push("/users-management/clients");
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },

    async update_Client(body) {
      let combined_data = {
        id: this.$route.params.id,
        body: body,
      };
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("EditClient", combined_data);
        this.$store.commit("makingApiRequest", false);

        this.$notify({
          title: "Client updated successfully!",
          group: "success",
        });
        await this.$router.push({
          name: "all-clients",
          params: this.$route.params.id,
        });
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },
  },

  watch: {
    agency() {
      this.client_name = this.client.client_name;
      this.contact_email = this.client.contact_email;
      this.contact_phone_number = this.client.contact_phone_number;
      this.agency_id = this.client.agency_id;
    },
  },
};
</script>