<template>
  <v-form v-model="valid" class="campaign-form" ref="form" lazy-validation>
    <v-row>
      <v-col>
        <general-card class="pa-5 mt-5 three-quarter-height">
          <h6 class="mb-2">Client Name</h6>
          <v-text-field
            label="Name of Client"
            dense
            outlined
            required
            class="mb-4"
            v-model="client_name"
            :rules="[(v) => !!v || 'client name is needed!']"
          ></v-text-field>

          <h6 class="mb-2">Contact Email</h6>
          <v-text-field
            outlined
            label="Admin Contact Email"
            type="email"
            dense
            required
            class="mb-4"
            v-model="contact_email"
            :rules="[(v) => !!v || 'Input a valid email to proceed!']"
          ></v-text-field>

          <h6 class="mb-2">Contact Phone Number</h6>
          <v-text-field
            outlined
            label="Admin Contact Phone Number"
            dense
            required
            v-model="contact_phone_number"
            :rules="[(v) => !!v || 'Enter a valid phone number to proceed!']"
          ></v-text-field>
        </general-card>
      </v-col>
      <v-col v-if="superAdmin">
        <general-card class="pa-5 mt-5 three-quarter-height">
          <h6 class="mb-2">Select Agency</h6>
          <v-select
            outlined
            dense
            label="Select Agency"
            class="mb-4"
            :items="agencies"
            required
            item-text="agency_name"
            item-value="id"
            v-model="agency_id"
            :rules="[(v) => !!v || 'Select an agency to proceed!']"
          ></v-select>
          <v-card-actions class="d-flex justify-end align-center mt-10">
            <v-btn color="primary" small @click="handleSubmit" class="mt-10">{{
              this.$route.name == "add-client" ? "Add Client" : "Update Client"
            }}</v-btn>
          </v-card-actions>
        </general-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import GeneralCard from "../Layouts/Cards/GeneralCard.vue";

export default {
  components: {
    GeneralCard,
  },
  data: () => ({
    valid: true,
    agency_id: null,
    client_name: "",
    contact_email: "",
    contact_phone_number: "",
    account_types: ["Agency", "Individual"],
    //agencies:["MediaPal", "Densu", "Safaricom", "Super_Agents"],
    account_type: "",
    checked: false,
  }),
  props: {
    client: {
      type: Object,
    },
    agencies: {
      type: Array,
    },
  },

  methods: {
    async handleSubmit() {
      await this.validate();
      if (!this.valid) return;

      if (this.$route.name == "add-client") {
        this.submit();
      } else {
        this.client_update();
      }
    },
    submit() {
      let body = {
        client_name: this.client_name,
        contact_email: this.contact_email,
        contact_phone_number: this.contact_phone_number,
        agency_id: this.agency_id,
      };
      this.$emit("create", body);
    },
    client_update() {
      let body = {
        client_name: this.client_name,
        contact_email: this.contact_email,
        contact_phone_number: this.contact_phone_number,
        agency_id: this.agency_id,
      };

      this.$emit("update", body);
    },
    async validate() {
      await this.$refs.form.validate();
    },
  },
  computed: {
    currentRoute() {
      return this.$route.name == "add-client";
    },
    superAdmin() {
      return this.$store.getters.superAdmin;
    },
  },
  watch: {
    client() {
      if (
        this.$route.name === "update-client" &&
        this.client &&
        this.agencies
      ) {
        this.client_name = this.client.client_name
          ? this.client.client_name
          : null;
        this.contact_email = this.client.contact_email
          ? this.client.contact_email
          : null;
        this.contact_phone_number = this.client.contact_phone_number
          ? this.client.contact_phone_number
          : null;
        this.agency_id = this.client.agency_id ? this.client.agency_id : null;
      }
    },
  },
};
</script>

<style>
</style>